<template>
  <div class="analysis_look">
    <div class="info">
      <p class="tt">{{ this.info.name }}</p>
      <div class="questionnaire_info">
        <p>填报问卷：{{ this.info.type }}</p>
        <p>提交时间：{{ this.info.time }}</p>
      </div>
      <div class="questionnaire_info">
        <p>联系人：{{ this.info.contact }}</p>
        <p>联系电话：{{ this.info.phone }}</p>
        <p>商企经理：{{ this.info.manager }}</p>
        <p>邀请码：{{ this.info.code }}</p>
        <p>完整度：{{ this.info.integrity }}%</p>
      </div>
    </div>
    <div class="list">
      <el-scrollbar class="quest-scroll">
        <div class="item" v-for="(item,index) in information" :key="item.id">
          <h3>{{ item.title }}</h3>
          <ul>
            <li v-for="(item1,index) in item.question" :key="item1.id">
              <p class="tt">{{index+1}}. {{item1.title}}</p>
              <p class="tt" v-for="(item2,index) in item1.answer" :key="item2.id">答：{{ item2.content }}</p>
            </li>
          </ul>
        </div>
      </el-scrollbar>
    </div>
    <div class="btn">
      <a href="JavaScript:;" @click="closeWindow" class="view_close">关闭窗口</a>
    </div>
  </div>
</template>

<script>
import {questionnaire_contact_detail} from "../../request/questionApi";
import {timeFormat} from "@/utils/index.js"
export default {
  name: "looksurvey",
  data(){
    return {
      info:{
        name:'重庆斯睿机器人科技有限公司',
        type:'中小企业数字化转型评测问卷',
        time:'2024-03-10 12:23:34',
        contact:'杨航',
        phone:15123136501,
        manager:'张三丰(zhangsanfengf)',
        code:'1234',
        integrity:'85%'
      },
      information:[{
        problem:'您的企业全称叫什么？( 必填 )',
        answer:'答：重庆斯睿机器人科技有限公司'
      },
        {
          problem:'您的企业属于哪种企业类型？( 必填 )',
          answer:'答：有限责任公司'
        },
        {
          problem:'您的企业统一社会信用代码是多少？( 必填 )',
          answer:'答：91500225MA5YP9TC09'
        },
        {
          problem:'您的企业详细地址在哪里？( 必填 )',
          answer:'答：重庆市大足区高新技术产业开发区'
        },
        {
          problem:'您的企业全称叫什么？( 必填 )',
          answer:'答：重庆斯睿机器人科技有限公司'
        },
        {
          problem:'您的企业属于哪种企业类型？( 必填 )',
          answer:'答：有限责任公司'
        },
        {
          problem:'您的企业详细地址在哪里？( 必填 )',
          answer:'答：91500225MA5YP9TC09'
        },
        {
          problem:'您的企业全称叫什么？( 必填 )',
          answer:'答：重庆市大足区高新技术产业开发区'
        },
      ],
      enterprise:[
        {
          problem:'您的企业全称叫什么？( 必填 )',
          answer:'答：重庆斯睿机器人科技有限公司'
        },
        {
          problem:'您的企业属于哪种企业类型？( 必填 )',
          answer:'答：有限责任公司'
        },
        {
          problem:'您的企业统一社会信用代码是多少？( 必填 )',
          answer:'答：91500225MA5YP9TC09'
        },
        {
          problem:'您的企业详细地址在哪里？( 必填 )',
          answer:'答：重庆市大足区高新技术产业开发区'
        },
        {
          problem:'您的企业全称叫什么？( 必填 )',
          answer:'答：重庆斯睿机器人科技有限公司'
        },
        {
          problem:'您的企业属于哪种企业类型？( 必填 )',
          answer:'答：有限责任公司'
        },
        {
          problem:'您的企业详细地址在哪里？( 必填 )',
          answer:'答：91500225MA5YP9TC09'
        },
        {
          problem:'您的企业全称叫什么？( 必填 )',
          answer:'答：重庆市大足区高新技术产业开发区'
        },
      ]
    }
  },
  mounted() {
    let id = this.$route.query.id
    this.$api.questionnaire_contact_detail({id:id}).then(res=>{
      this.info.name = res.data.company
      this.info.type = res.data.title
      this.info.time = timeFormat(res.data.committime, 'yyyy-mm-dd hh:MM:ss')
      this.info.contact = res.data.truename
      this.info.phone = res.data.mobile
      this.info.code = res.data.code
      this.info.integrity = res.data.integrity
      this.info.manager = res.data.bussiness
      this.information = res.data.dir
      console.log(res)
    })
  },
  methods:{
    closeWindow(){
      window.close();
    }
  }
}
</script>

<style scoped lang="scss">
.analysis_look {
  position: fixed;
  bottom: 20px;
  top: 20px;
  padding-bottom: 100px;
  width: 41.7%;
  left: 50%;
  margin-left: -25%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(153, 153, 153, .15);
  .info {
    padding: 20px;
    .questionnaire_info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #909DA5;
      font-size: 12px;
      line-height: 32px;
    }
    .tt {
      margin-bottom: 6px;
      color: #242d33;
      font-size: 18px;
      line-height: 32px;
      padding-top: 0;
    }
    .blue {
      color: #1190f3;
    }
    .other {
      color: #98a0a6;
      padding-top: 7px;
      span {
        padding-right: 34px;
      }
      b {
        font-weight: normal;
      }
      .green {
        color: #04cd83;
      }
    }
  }
  .list {
    position: absolute;
    top: 130px;
    bottom: 90px;
    left: 20px;
    right: 20px;
    border-radius: 3px;
    background: #FAFBFC;
    line-height: 32px;
    padding-top: 20px;
    box-sizing: border-box;
    h3 {
      margin-bottom: 5px;
      font-size: 16px;
      color: #242D33;
      font-weight: bold;
    }
    li {
      border-bottom: 1px dashed #eee;
      padding-bottom: 11px;
      margin-bottom: 10px;
    }
    .item {
      padding: 0 20px;
      .ins {
        color: #60696f;
      }
    }
    .nano-content {
      padding: 14px 0;
    }
  }
  .table_tt {
    margin: 0 20px;
  }
  .btn {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 30px 0;
    font-size: 0;
    text-align: center;
    a,
    button {
      margin: 0 5px;
      display: inline-block;
      line-height: 38px;
      border-radius: 2px;
      font-size: 14px;
      color: #242d33;
      font-weight: bold;
      border: 1px solid #eee;
    }
    .sure {
      background-color: #1190f3;
      border-color: #1190f3;
      color: #fff;
      &:hover {
        background-color: #0073cc;
      }
    }
    .view_btn{
      color: #fff;
      width: 118px;
      height: 40px;
      background: #3984F5;
      border-radius: 4px;
    }
    .view_close{
      width: 104px;
      height: 40px;
      background: #EEF1F3;
      border-radius: 4px;
      color: #6C7880;
    }
  }
}
::v-deep .quest-scroll {
  height: calc(100vh - 300px);
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-scrollbar__bar.is-vertical {
    width: 4px;
  }
  .el-scrollbar__thumb {
    background: #A7B2B8;
  }
}
</style>